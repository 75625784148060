import {
  ActionIcon,
  Avatar,
  Box,
  Grid,
  Group,
  Paper,
  Text
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import Link from 'next/link';

import { Paths } from '@/core/constants/routes.constants';
import { TaskType } from '@/core/constants/tasks-and-frameworks.constants';
import { useDeleteRun } from '@/core/hooks/query-hooks/use-delete-run/use-delete-run';
import useProjectType from '@/core/hooks/use-project-type/use-project-type';
import useTaskType from '@/core/hooks/use-task-type/use-task-type';
import { components } from '@/core/types/api';
import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';
import { parseDate } from '@/core/utils/parse-date/parse-date';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import { toHumanReadableTaskType } from '@/core/utils/to-human-readable-task-type/to-human-readable-task-type';

type Run = components['schemas']['RunDBThinWithProjectName'];

type UrlProps = {
  pathname: string;
  query: {
    projectId: string;
    runId?: string;
    taskType?: number;
  };
};

const getCreator = (element: Run) => {
  if (element?.creator?.first_name) {
    return element?.creator?.first_name?.[0];
  }

  return '-';
};
/**
 * RunCard
 *
 *
 *
 * @returns {React.Component} RunCard
 */
const RunCard = ({ run }: { run: Run }) => {
  const deleteRun = useDeleteRun();

  const isObserve = run.task_type === 9;
  const isPromptInspector = run.task_type === 7 || run.task_type === 12;
  const isPromptInspectorChains = run.task_type === 12;
  const isTrainingInference = !isObserve && !isPromptInspector;

  let pathname = Paths.INSIGHTS;
  let projectType: ProjectType = PROJECT_TYPE.TRAINING_INFERENCE;

  if (isPromptInspector) {
    pathname = Paths.PROMPT_PROMPTS;
    projectType = PROJECT_TYPE.PROMPT_EVALUATION;
  }

  if (isPromptInspectorChains) {
    pathname = Paths.PROMPT_CHAINS_RUNS;
    projectType = PROJECT_TYPE.PROMPT_EVALUATION;
  }

  if (isObserve) {
    pathname = Paths.OBSERVE;
    projectType = PROJECT_TYPE.OBSERVE;
  }

  const url: UrlProps = {
    pathname,
    query: {
      projectId: run.project_id || ''
    }
  };

  if (isTrainingInference) {
    url.query = {
      ...url.query,
      runId: run.id,
      taskType: run.task_type || undefined
    };
  }

  if (isPromptInspectorChains || isPromptInspector) {
    url.query = {
      ...url.query,
      runId: run.id
    };
  }

  const runName = isObserve ? run.project_name || run.name : run.name;

  const { Icon: ProjectTypeIcon } = useProjectType(projectType);
  const { Icon: TaskTypeIcon, color: taskTypeColor } = useTaskType(
    run.task_type?.toString() as TaskType
  );

  const handleDeleteRun = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    deleteRun.mutate({ runId: run.id, projectId: run.project_id || '' });
  };

  return (
    <Link
      className='unstyled-anchor'
      data-testid='run-card'
      href={url}
      key={run.id}
    >
      <Paper
        h={isObserve ? 66 : 85}
        miw={310}
        p='xs'
        radius={8}
        style={{
          border: '1px solid #E9E8ED'
        }}
        w={310}
      >
        <Grid className='h-100' gutter={2}>
          <Grid.Col span={1}>
            {isPromptInspectorChains || isTrainingInference ? (
              <TaskTypeIcon color={taskTypeColor} size={16} />
            ) : (
              <ProjectTypeIcon color='#9B98AE' size={16} />
            )}
          </Grid.Col>
          <Grid.Col span={11}>
            <Group justify='space-between' mb={isObserve ? 5 : 0}>
              <Text className='truncate' maw={200} size='sm'>
                {runName}
              </Text>
              <ActionIcon mr={3} size='xs' onClick={handleDeleteRun}>
                <IconTrash size={16} />
              </ActionIcon>
            </Group>

            {!isObserve && (
              <Text c='dimmed' className='truncate' mt={2} size='xs'>
                <Text component='span' fw={600}>
                  Project:
                </Text>{' '}
                {run?.project_name}
              </Text>
            )}
            <Group justify='space-between'>
              <Group>
                <Box className='align-center'>
                  <Text c='dimmed' ml={2} size='xs'>
                    {parseDate(run?.created_at || '')}
                  </Text>

                  <Text c='dimmed' ml={2} size='xs'>
                    &#8226;
                  </Text>
                  <Text c='dimmed' ml={2} mr={2} size='xs'>
                    {toHumanReadableNumber(run?.num_samples, {
                      fallback: 0
                    })}{' '}
                    samples
                  </Text>
                  {isTrainingInference && (
                    <>
                      <Text c='dimmed' mr={2} size='xs'>
                        &#8226;
                      </Text>

                      <Text c='dimmed' mr={2} size='xs'>
                        {toHumanReadableTaskType(
                          run?.task_type?.toString() as TaskType
                        )}
                      </Text>
                    </>
                  )}
                </Box>
              </Group>
              <Avatar
                data-testid='creator-avatar'
                radius='xl'
                size={24}
                styles={{
                  placeholder: {
                    backgroundColor: '#9791F3',
                    color: 'white',
                    fontSize: 12
                  }
                }}
              >
                {getCreator(run)}
              </Avatar>
            </Group>
          </Grid.Col>
        </Grid>
      </Paper>
    </Link>
  );
};

export default RunCard;
