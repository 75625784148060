import {
  IconBarbell,
  IconFloatRight,
  IconGrain,
  IconLayersLinked,
  IconLayoutCollage,
  IconManualGearbox,
  IconPhotoSearch,
  IconPictureInPictureOff,
  IconPuzzle
} from '@tabler/icons-react';

import {
  TASK_TYPE,
  TaskType
} from '@/core/constants/tasks-and-frameworks.constants';

const useTaskType = (taskType: TaskType) => {
  const DefaultIcon = IconBarbell;
  const DefaultColor = '#8674FB';

  switch (taskType) {
    case TASK_TYPE.IC:
      return {
        label: 'Image Classification',
        Icon: IconPictureInPictureOff,
        color: '#00BB8F'
      };
    case TASK_TYPE.MCTC:
      return {
        label: 'Text Classification',
        Icon: IconGrain,
        color: DefaultColor
      };
    case TASK_TYPE.NER:
      return {
        label: 'Named Entity Recognition',
        Icon: IconFloatRight,
        color: '#DCB104'
      };
    case TASK_TYPE.MLTC:
      return {
        label: 'Multilabel Text Classification',
        Icon: IconManualGearbox,
        color: '#8674FB'
      };
    case TASK_TYPE.NLI:
      return {
        label: 'Natural Language Inference',
        Icon: DefaultIcon,
        color: DefaultColor
      };
    case TASK_TYPE.OD:
      return {
        label: 'Object Detection',
        Icon: IconPhotoSearch,
        color: '#00BB8F'
      };
    case TASK_TYPE.PE:
      return {
        label: 'Evaluate',
        Icon: DefaultIcon,
        color: DefaultColor
      };
    case TASK_TYPE.PM:
      return {
        label: 'Observe',
        Icon: DefaultIcon,
        color: DefaultColor
      };
    case TASK_TYPE.PROMPT_CHAINS:
      return {
        label: 'Evaluate Chains',
        Icon: IconLayersLinked,
        color: '#9B98AE'
      };
    case TASK_TYPE.S2S:
      return {
        label: 'Seq to Seq',
        Icon: DefaultIcon,
        color: DefaultColor
      };
    case TASK_TYPE.SD:
      return {
        label: 'Structured Data',
        Icon: DefaultIcon,
        color: DefaultColor
      };
    case TASK_TYPE.SS:
      return {
        label: 'Semantic Segmentation',
        Icon: IconLayoutCollage,
        color: '#00BB8F'
      };
    default:
      return {
        label: 'Unknown',
        Icon: IconPuzzle,
        color: DefaultColor
      };
  }
};

export default useTaskType;
